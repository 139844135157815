










































import { Component, PropSync, Vue } from 'vue-property-decorator';
import PocketIsOnMaintenanceViewModel
  from '@/vue-app/view-models/components/my-investment-dashboard-v2/pocket-is-on-maintenance-view-model';

@Component({
  name: 'PocketIsOnMaintenanceDialog',
  components: {},
})
export default class PocketIsOnMaintenanceDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  view_model = Vue.observable(
    new PocketIsOnMaintenanceViewModel(),
  );

  closeModal() {
    this.synced_is_open = false;
  }
}
